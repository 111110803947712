import React from "react"
import './App.css'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
         Stalk all you can 👽
        </p>
        <a
          className="App-link"
          href="https://www.instagram.com/rajeshgovada/"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
          
        >
          Instagram/rajeshgovada 📷
        </a>
        <a
          className="App-link"
          href="https://www.instagram.com/doctorqp/"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
        >
          Instagram/doctorqp ✍️
        </a>    
        <a
          className="App-link"
          href="https://www.youtube.com/@DoctorQP/featured"
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
        >
          YouTube/DoctorQP 📽️
        </a>                    
      </header>
    </div>
  );
}

export default App;
